import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Input from '../../components/form/input'
import Select from '../../components/form/select';
// import { AdminModal, DeleteModal } from '../../components/form/modal';
import Textarea from '../../components/form/textarea';
import { Loader } from '../../components/utilities/loader';
import { handleValidation } from '../../components/utilities/validations';
import { AddDevice } from '../../store/devices/deviceService';
import { RootState } from '../../store/rootReducer';
import { useAppDispatch } from '../../store/Store';

interface stateInterface {
    options: any[]
}

export default function DeviceAdd() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { addLoad, devices } = useSelector((state: RootState) => state.devices);
    const { customers } = useSelector((state: RootState) => state.customers);

    const [state, setState] = useState({ id: '', deviceName: '', serialNo: '', customerId: '', description: '', ipAddress: '', macAddress: '' });
    const [data, setData] = useState<stateInterface>({ options: [] });
    const [value, setValue] = useState({ deleteModal: false, adminModal: false, isUpdate: false, isEdit: false, fullName: '', email: '', deleteName: '', deleteIndex: -1, editIndex: -1 });
    const [error, setError] = useState<any>({});

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            let find = devices.find(o => o.id === Number(id));
            console.log(find)
            setState(state => ({ ...state, deviceName: find?.name, serialNo: find?.serialNum, customerId: find?.customerId, description: find?.description, ipAddress: find?.ipAddress, macAddress: find?.macAddress, id: id }));
            setValue(value => ({ ...value, isUpdate: true }));
        } else {
            setState(state => ({ ...state, deviceName: '', serialNo: '', customerId: '', description: '', id: '' }));
            setValue(value => ({ ...value, isUpdate: false }));
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (customers && customers.length > 0) {
            const customerList = customers.map((v: any, i: number) => {
                return {
                    key: v.id,
                    name: v.companyName
                }
            });
            setData(data => ({ ...data, options: customerList }));
        }
    }, [customers])

    const handleChange = (e: any) => {
        setState(state => ({ ...state, [e.target.name]: e.target.value }))
    }

    const handleSubmit = (e: any) => {
        let conditions = [
            { condition: 'required', name: 'deviceName', value: state.deviceName, text: 'Device Name' },
            { condition: 'required', name: 'serialNo', value: state.serialNo, text: 'Serial Number' },
            { condition: 'required', name: 'customerId', value: state.customerId, text: 'Customer' },
            { condition: 'required', name: 'ipAddress', value: state.customerId, text: 'IP Address' },
            { condition: 'required', name: 'macAddress', value: state.customerId, text: 'MAC Address' },
        ]
        let errors = handleValidation(conditions);
        if (!errors.isError) {
            setError({});
            console.log(state)
            let data = {
                config: '',
                serialNum: state.serialNo,
                customerId: state.customerId,
                description: state.description,
                name: state.deviceName,
                ipAddress: state.ipAddress,
                macAddress: state.macAddress,
                id: !state.id ? null : Number(state.id),
            }

            dispatch(AddDevice(data)).then((resp: any) => {
                if (resp.status === 200 && resp.data?.isValid) {
                    navigate('/base-station-list');
                }

            });
        } else {
            setError(errors.errors)
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="row d-flex align-items-center mb-3 mb-md-5">
                    <div className="col-md-6">
                        <p className="medium ft-23 mb-2 mb-md-0">{`${value.isUpdate ? 'Edit' : 'Add'} Base Station`}</p>
                    </div>
                    <div className="col-md-6 text-start text-md-end">
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-lg-10">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Input type='text' label='Serial Number' autoFocus={true} value={state.serialNo} placeholder='Serial Number' name='serialNo' onchange={handleChange} error={error?.serialNo} required={true} />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input type='text' label='Device Name' value={state.deviceName} placeholder='Device Name' name='deviceName' onchange={handleChange} error={error?.deviceName} required />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input type='text' label='MAC Address' value={state.macAddress} placeholder='MAC Address' name='macAddress' onchange={handleChange} error={error?.macAddress} required={true} />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input type='text' label='IP Address' value={state.ipAddress} placeholder='IP Address' name='ipAddress' onchange={handleChange} error={error?.ipAddress} required />
                            </div>
                            <div className="col-12 col-md-12">
                                <Select label='Select Customer' value={state.customerId} options={data.options} name='customerId' onchange={handleChange} error={error?.customerId} required />
                            </div>
                            <div className="col-12">
                                <Textarea label='Description' value={state.description} placeholder='Description' name='description' onchange={handleChange} maxlength={511} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center mt-4 mb-5">
                                <button className={value.isUpdate ? "btn btn-warning" : "btn btn-theme"} onClick={(e) => addLoad ? null : handleSubmit(e)}> {addLoad ? <Loader /> : value.isUpdate ? <i className='fa fa-edit' /> : <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>} {` ${addLoad ? 'Loading' : `${value.isUpdate ? 'Edit ' : 'Add '} Device`}`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {value.deleteModal ?
                <DeleteModal text={`Are you sure to Delete ${value.deleteName}?`} onclose={() => setValue({ ...value, deleteModal: false, deleteName: '', deleteIndex: -1 })} ondelete={() => handleAdminDelete(value.deleteIndex)} /> : null} */}

        </React.Fragment>
    )
}