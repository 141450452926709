import { useState } from "react";
import { Link } from 'react-router-dom';
import { APP_VERSION } from "../../version";

function Forgot() {
    // let { login } = useSelector((state: RootState) => state);
    // const dispatch = useDispatch();

    const [state, setState] = useState({ modal: false });

    // const handleLogin = (e: any) => {
    //     // const dispatch(useDispatch
    //     // dispatch(UserLogin({ username: 'Oppp', password: 'sdf' }))
    // }

    return (
        <div>
            <div>
                <div className="login-box">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8 col-lg-5 text-center">
                                <img src="img/logo.png" className="img-fluid mb-4" alt="img" />
                                <p className="medium ft-23 mb-1">Forgot Password</p>
                                <p className="mb-4">Enter the email associated with your account and we will send an email with instruction to reset your password.</p>
                                {/* <form> */}
                                <div className="form-group position-relative">
                                    <img src="img/icon/mail-outline.png" className="img-fluid login-fc-icon" alt={'img'} />
                                    <input type="text" name='' placeholder="Email address" className="login-form-control" />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-theme w-100" data-bs-toggle="modal" data-bs-target="#sendinstructions" onClick={() => setState(state => ({ ...state, modal: true }))}>Send Instructions!</button>
                                </div>
                                <div className="form-group text-center">
                                    <Link to="/login"><span className="text-theme"><i className='fa fa-cog' /> Login</span></Link>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="s-footer text-center copyright">
                    @ Canyon Display Technologies 2021-2022 | {APP_VERSION}
                </footer>
            </div>

            <div className={`modal fade ${state.modal ? 'show shown' : ''}`} id="sendinstructions" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" >
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button onClick={() => setState(state => ({ ...state, modal: false }))} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body text-center">
                            <img src="img/icon/twotone-mark-email-read.png" className="img-fluid mb-4" alt="img" />
                            <p className="medium ft-23 mb-1">Check your Email!</p>
                            <p className="mb-4">We have sent a password recover instruction to your mail</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Forgot;
