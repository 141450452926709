import React from 'react'

type Props = {
    label: string,
    name: string,
    value: string,
    onchange: any,
    placeholder: string,
    error?: string,
    required?: boolean,
    rows?: number,
    maxlength?: number
}

export default function Textarea({ label, name, value, onchange, placeholder, rows = 4, required = false, error = '', maxlength }: Props) {
    console.log('max ', maxlength)
    return (
        <div className="form-group">
            <label className="mb-2">{label}</label>
            <textarea className="form-control" placeholder={placeholder} name={name} rows={rows} value={value} onChange={onchange} maxLength={maxlength} />
        </div>
    )
}