import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ViewModal } from '../../components/form/modal';
import { Loader } from '../../components/utilities/loader';
import DataTable from '../../components/utilities/table';
import { Navigation } from '../../store/common/commonService';
import { ListDevices } from '../../store/devices/deviceService';
import { RootState } from '../../store/rootReducer';

interface stateInterface {
    data: any[]
}
interface modalInterface {
    deleteModal: boolean,
    viewModal: boolean,
    deleteData: any,
    viewData: any
}

export default function DeviceList() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [state, setState] = useState<stateInterface>({ data: [] });
    const [modal, setModal] = useState<modalInterface>({ deleteModal: false, viewModal: false, deleteData: {}, viewData: {} });

    const { load, devices } = useSelector((state: RootState) => state.devices);

    useEffect(() => {
        dispatch(ListDevices());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (devices && !load) {
            console.log('this called')
            const data = devices.map((val: any, i: number) => {
                return {
                    no: i + 1,
                    serialNo: val.serialNum,
                    deviceName: val.name,
                    addedDate: val.createdDate ? moment(val.createdDate * 1000).format("MM/DD/YYYY") : '',
                    allottedCustomer: val.customerName,
                    description: val.description,
                    macAddress: val.macAddress,
                    ipAddress: val.ipAddress,
                    id: val.id,
                }
            });
            setState(state => ({ ...state, data: data }))
        }
        // eslint-disable-next-line
    }, [load]);

    const header: any[] = [
        { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 70 },
        { headerName: 'Serial Number', field: 'serialNo', editable: false, resizable: true, },
        { headerName: 'Device Name', field: 'deviceName', editable: false, resizable: true },
        { headerName: 'MAC Address', field: 'macAddress', editable: false, resizable: true },
        { headerName: 'IP Address', field: 'ipAddress', editable: false, resizable: true },
        { headerName: 'Allotted Customer', field: 'allottedCustomer', editable: false, resizable: true },
        { headerName: 'Added Date', field: 'addedDate', editable: false, resizable: true },
        {
            headerName: 'Action', editable: false, minWidth: 200, cellRendererFramework: (param: any) => {
                return (
                    <React.Fragment>
                        <i className='fa fa-eye icon-padding fz-17' onClick={() => viewHandle(param.data)} />
                        <i className='fa fa-edit text-primary icon-padding fz-17' onClick={() => navigate('/base-station-edit/' + param.data.id)} />
                    </React.Fragment>
                )
            }
        },
    ];

    const viewHandle = (data: any) => {
        setModal(modal => ({ ...modal, viewModal: true, viewData: data }))
    }

    // const deleteHandle = (data: any) => {
    //     setModal(modal => ({ ...modal, deleteModal: true, deleteData: data }));
    // }

    return (
        <div>
            <div className="row d-flex align-items-center mb-3 mb-md-3">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">Base Stations List {load ? <Loader /> : null}</p>
                </div>
                <div className="col-md-6 text-start text-md-end">
                    <Link to='/base-station-add'><button onClick={() => dispatch(Navigation('deviceAdd'))} className="btn btn-theme"> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg> Add Base Station</button></Link>
                </div>
            </div>

            <DataTable key={Math.random()} headers={header} data={state.data} pagination={true} globalSearch={true} />

            {modal.viewModal ?
                <ViewModal title={'Customer View'} onclose={() => setModal(modal => ({ ...modal, viewModal: false, viewData: {} }))}>
                    <div className="row d-flex align-items-center mb-3 mb-md-5">
                        <div className="col-md-6">
                            <p className="medium ft-23 mb-2 mb-md-0" style={{ marginLeft: '2rem' }}>Base Station View</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row">
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        Serial Number
                                        <div className="ms-3 text-primary" style={{ overflow: "auto" }}>
                                            {modal.viewData?.serialNo}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        Device Name
                                        <div className="ms-3 text-primary" style={{ overflow: "auto" }}>
                                            {modal.viewData?.deviceName}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        Customer
                                        <div className="ms-3 text-primary" style={{ overflow: "auto" }}>
                                            {modal.viewData?.allottedCustomer}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        MAC Address
                                        <div className="ms-3 text-primary" style={{ overflow: "auto" }}>
                                            {modal.viewData?.macAddress}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        IP Address
                                        <div className="ms-3 text-primary" style={{ overflow: "auto" }}>
                                            {modal.viewData?.ipAddress}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        Description
                                        <div className="ms-3 text-primary" style={{ overflow: "auto" }}>
                                            {modal.viewData?.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </ViewModal>
                : null}
        </div>
    )
}
