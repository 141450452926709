import axios from "axios";
import { Dispatch } from "react";
import { addDevice, listDevice } from '../API';
import { Types } from "../types";

export const ListDevices = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.DEVICE_REQUEST });
        axios.get(listDevice).then(resp => {
            console.log(resp)
            if (resp.status === 200) {
                dispatch({ type: Types.DEVICE_SUCCESS, data: resp.data.cpeList });
            } else {
                dispatch({ type: Types.DEVICE_ERROR });
            }
        }).catch(err => {
            dispatch({ type: Types.DEVICE_ERROR });
        })
    }
};

export const AddDevice = (data: any) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.DEVICE_ADD_LOADING });
        return axios.post(addDevice, data).then(resp => {
            if (resp.status === 200 && resp.data?.isValid) {
                dispatch({ type: Types.DEVICE_ADD_SUCCESS });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Device ${data.id ? 'Updated' : 'Added'} Successfully`, duration: 2000 } });
            } else if (!resp.data?.isValid) {
                dispatch({ type: Types.DEVICE_ADD_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data?.message } });
            } else {
                dispatch({ type: Types.DEVICE_ADD_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed to Create Device, Try Again' } });
            }
            return resp;
        }).catch(err => {
            console.log('ERROR HERER ', err, err.response)
            dispatch({ type: Types.DEVICE_ADD_ERROR });
            dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed to Create Device, Try Again' } });
        })
    }
};

// export const AddAdmin = (data: any) => {
//     return (dispatch: Dispatch<any>) => {
//         dispatch({ type: Types.ADMIN_ADD_LOADING });
//         axios.post(addUserUrl + '/user', data).then(resp => {
//             if (resp.status === 200 && resp.data?.isValid) {
//                 dispatch({ type: Types.ADMIN_ADD_SUCCESS });
//                 dispatch(CustomersList());
//                 dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Admin ${data.userId ? 'Updated' : 'Added'} Successfully`, duration: 2000 } });
//             } else {
//                 dispatch({ type: Types.ADMIN_ADD_ERROR });
//                 dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data?.message ?? 'Failed to Create Admin, Try Again' } });
//             }
//         }).catch(err => {
//             dispatch({ type: Types.ADMIN_ADD_ERROR });
//             dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed to Create Admin, Try Again' } });
//         })
//     }
// };
