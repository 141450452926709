import { Types } from "../types";

interface ICustomersState {
    load: boolean,
    addLoad: boolean,
    adminLoad: boolean,
    devices: any[],
}

const initialState: ICustomersState = {
    load: false,
    addLoad: false,
    adminLoad: false,
    devices: [],
}

export const deviceReducer = (state: ICustomersState = initialState, action: any) => {
    switch (action.type) {

        case Types.DEVICE_REQUEST:
            return {
                ...state,
                load: true,
            }

        case Types.DEVICE_SUCCESS:
            return {
                ...state,
                load: false,
                devices: [...action.data]
            }

        case Types.DEVICE_ERROR:
            return {
                ...state,
                load: false,
            }

        case Types.DEVICE_ADD_LOADING:
            return {
                ...state,
                addLoad: true,
            }

        case Types.DEVICE_ADD_SUCCESS:
            return {
                ...state,
                addLoad: false,
            }

        case Types.DEVICE_ADD_ERROR:
            return {
                ...state,
                addLoad: false,
            }

        default:
            return state;
    }
}