import React, { useState } from 'react'
import { Loader } from '../utilities/loader'
import Input from './input'

type DeleteProps = {
    text: string,
    ondelete: any,
    onclose: any
}

export function DeleteModal({ text, ondelete, onclose }: DeleteProps) {
    return (
        <div className="modal fade show shown" id="deleteModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" onClick={onclose} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body text-center">
                        <p className="medium ft-23 mb-1">{text}</p>
                        <div className="text-center mb-5 mt-4">
                            <span className="btn btn-secondary" onClick={onclose}>Close</span>
                            {/* <span className="btn btn-danger" onClick={ondelete}>Delete</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

type AdminProps = {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    onchange: any,
    onclose: any,
    onsubmit: any,
    onedit: any,
    isedit: boolean,
    errors: any,
    load: boolean,
}

export function AdminModal({ firstName, lastName, email, password, onchange, onclose, onsubmit, isedit, onedit, errors, load }: AdminProps) {
    const [viewpassword, setviewPassword] = useState(false);
    return (
        <div className="modal fade show shown" id="addadminModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" onClick={onclose} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body text-center">
                        <p className="medium ft-23 mb-1">{`${isedit ? 'Edit' : 'Add'} Admin`}</p>
                        <form className="text-start" onSubmit={(e) => onsubmit(e)}>
                            <div className="row">
                                <div className="col-6">
                                    <Input type={'text'} autoFocus={true} placeholder='First Name' value={firstName} label={'First Name'} onchange={onchange} name='firstName' error={errors.firstName} required />
                                </div>
                                <div className="col-6">
                                    <Input type={'text'} autoFocus={false} placeholder='Last Name' value={lastName} label={'Last Name'} onchange={onchange} name='lastName' error={errors.lastName} required />
                                </div>
                                <div className={`col-${isedit ? '12' : '6'}`}>
                                    <Input type={'text'} placeholder='Email Address' value={email} label={'Email Address'} onchange={onchange} name='email' error={errors.emailAddr} required />
                                </div>
                                {isedit ? null :
                                    <div className="col-6">
                                        <Input type={viewpassword ? 'text' : 'password'} placeholder='Password' value={password} label={'Password'} onchange={onchange} name='password' error={errors.password} required />
                                        <span className='view-password' onClick={() => setviewPassword(!viewpassword)}>{viewpassword ? 'Hide Password' : 'View Password'}</span>
                                    </div>}
                                <div className="text-center mb-5 mt-4">
                                    <span className="btn btn-secondary" onClick={onclose}>Cancel</span>
                                    <button type='submit' className={isedit ? "btn btn-warning" : "btn btn-theme"} onClick={(e) => isedit ? onedit(e) : onsubmit(e)}>{load ? <><Loader /> Loading</> : isedit ? "Edit Admin" : "Add Admin"}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ViewModal({ children, onclose, title }: any) {
    return (
        <div className="modal fade show shown" id="addadminModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" onClick={onclose} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body modal-scroll">
                        {/* <p className="medium ft-23 mb-1">{title}</p> */}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

