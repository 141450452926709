import { Types } from "../types";

interface IUserLoginState {
    message: string,
    alert: boolean,
    type: string,
    duration: number,
}

const initialState: IUserLoginState = {
    alert: false,
    message: '',
    type: '',
    duration: 3000,
}

interface INavigation {
    menu: string,
}

export const alertReducer = (state: IUserLoginState = initialState, action: any) => {
    switch (action.type) {

        case Types.ALERT_ERROR:
            return {
                ...state,
                alert: true,
                type: 'error',
                message: action.payload.message ?? '',
                duration: action.payload.duration ?? state.duration,
            }

        case Types.ALERT_SUCCESS:
            return {
                ...state,
                alert: true,
                type: 'success',
                message: action.payload.message ?? '',
                duration: action.payload.duration ?? state.duration,
            }
        case Types.ALERT_WARNING:
            return {
                ...state,
                alert: true,
                type: 'warning',
                message: action.payload.message ?? '',
                duration: action.payload.duration ?? state.duration,
            }

        case Types.ALERT_INFO:
            return {
                ...state,
                alert: true,
                type: 'info',
                message: action.payload.message ?? '',
                duration: action.payload.duration ?? state.duration,
            }

        case Types.ALERT_DEFAULT:
            return {
                ...state,
                alert: true,
                type: '',
                message: action.payload.message ?? '',
                duration: action.payload.duration ?? state.duration,
            }

        case Types.ALERT_CLEAR:
            return {
                ...state,
                alert: false,
                type: '',
                message: '',
                duration: 3000,
            }

        default:
            return state;
    }
}

export const navigationReducer = (state: INavigation = { menu: 'dashboard' }, action: any) => {
    switch (action.type) {

        case Types.NAVIGATION_LABEL:
            return {
                menu: action.payload,
            }

        default:
            return state;
    }
}

interface IDashboard {
    dashboardLoading: boolean,
    dashboardData: object,
}

const dashboardState: IDashboard = {
    dashboardLoading: false,
    dashboardData: []
}

export const dashboardReducer = (state: IDashboard = dashboardState, action: any) => {
    switch (action.type) {

        case Types.DASHBOARD_REQUEST:
            return {
                ...state,
                dashboardLoading: true,
            }

        case Types.DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardLoading: false,
                dashboardData: action.payload ?? []
            }
        case Types.ALERT_WARNING:
            return {
                ...state,
                dashboardLoading: false,
            }

        default:
            return state;
    }
}