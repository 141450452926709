export enum Types {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_ERROR = 'LOGIN_ERROR',
    USER_LOGOUT = 'USER_LOGOUT',

    ALERT_ERROR = 'ALERT_ERROR',
    ALERT_SUCCESS = 'ALERT_SUCCESS',
    ALERT_WARNING = 'ALERT_WARNING',
    ALERT_INFO = 'ALERT_INFO',
    ALERT_DEFAULT = 'ALERT_DEFAULT',
    ALERT_CLEAR = 'ALERT_CLEAR',

    CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST',
    CUSTOMERS_SUCCESS = 'CUSTOMERS_SUCCESS',
    CUSTOMERS_ERROR = 'CUSTOMERS_ERROR',

    CUSTOMERS_ADD_LOADING = 'CUSTOMERS_ADD_LOADING',
    CUSTOMERS_ADD_SUCCESS = 'CUSTOMERS_ADD_SUCCESS',
    CUSTOMERS_ADD_ERROR = 'CUSTOMERS_ADD_ERROR',

    ADMIN_ADD_LOADING = 'ADMIN_ADD_LOADING',
    ADMIN_ADD_SUCCESS = 'ADMIN_ADD_SUCCESS',
    ADMIN_ADD_ERROR = 'ADMIN_ADD_ERROR',

    DEVICE_REQUEST = 'DEVICE_REQUEST',
    DEVICE_SUCCESS = 'DEVICE_SUCCESS',
    DEVICE_ERROR = 'DEVICE_ERROR',

    DEVICE_ADD_LOADING = 'DEVICE_ADD_LOADING',
    DEVICE_ADD_SUCCESS = 'DEVICE_ADD_SUCCESS',
    DEVICE_ADD_ERROR = 'DEVICE_ADD_ERROR',

    NAVIGATION_LABEL = 'NAVIGATION_LABEL',

    DASHBOARD_REQUEST = 'DASHBOARD_REQUEST',
    DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS',
    DASHBOARD_ERROR = 'DASHBOARD_ERROR',

}