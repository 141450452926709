import axios from 'axios';
import { Dispatch } from "react";
import { Types } from "../types";
import { loginUrl } from '../API';
import { CustomersList } from '../customers/customersService';

interface IUserLoginRequestData {
    userEmail: string,
    userPassword: string,
}

export const UserLogin = (data: IUserLoginRequestData) => {

    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.LOGIN_REQUEST });
        // axios.post(baseUrl + '/getToken', data).then(resp => {
        axios.post(loginUrl + '/getToken', data).then(resp => {
            if (resp.status === 200 && resp.data?.isValid) {
                if (resp.data.role === 'SUPER_ADMIN') {
                    setTimeout(() => {
                        dispatch(CustomersList());
                    }, 0);
                    localStorage.setItem('sa.token', resp.data.jwtToken);
                    dispatch({ type: Types.LOGIN_SUCCESS, payload: resp.data });
                } else {
                    dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Invalid Access' } });
                    dispatch({ type: Types.LOGIN_ERROR });

                }
                // dispatch({ type: Types.ALERT_SUCCESS, payload: { message: resp.data.jwtToken, duration: 4000 } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.LOGIN_ERROR });
            }
        }).catch((error) => {
            dispatch({ type: Types.ALERT_ERROR, payload: { message: error.response?.data?.message } })
            dispatch({ type: Types.LOGIN_ERROR });
        });
    }
};

export const UserLogout = () => {

    return (dispatch: Dispatch<any>) => {
        localStorage.removeItem('sa.token');
        dispatch({ type: Types.USER_LOGOUT, payload: {} });
        dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Logged Out', duration: 1000 } });
    }
};
