import axios from "axios";
import { useSelector } from "react-redux";
import Toast from "./components/toast";
import Routers from "./Routes";
import { RootState } from "./store/rootReducer";

function App() {

  const { userData } = useSelector((state: RootState) => state.login);

  const checkAuth = ['/login', '/forgot'];

  axios.interceptors.request.use((requests: any) => {
    const isAuth = checkAuth.includes(window.location.pathname);
    if (!isAuth && userData.jwtToken) {
      let token = localStorage.getItem('sa.token');
      requests.headers['Authorization'] = `Bearer ${token}`;
    }
    return requests;
  });

  return (
    <>
      <Toast />
      <Routers />
    </>
  );
}

export default App;
