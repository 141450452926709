// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from 'react-router-dom';
// import { Loader } from "../../components/utilities/loader";
// import { UserLogin } from "../../store/login/loginService";
// import { RootState } from '../../store/rootReducer';

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../components/utilities/loader";
import { DashboardData } from "../../store/common/commonService";
import { RootState } from "../../store/rootReducer";
import { useAppDispatch } from "../../store/Store";

function Dashboard() {
    const dispatch = useAppDispatch();
    const { dashboardLoading, dashboardData } = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        dispatch(DashboardData());
    }, [])

    return (
        <React.Fragment>
            <p className="medium ft-23 mb-4">Dashboard {dashboardLoading ? <Loader /> : null}</p>
            <div className="row">
                <div className="col-md-6 col-lg-6">
                    <Link to="/customer-list">
                        <div className="dashboard-box">
                            <p className="medium ft-23 mb-2">Total Customers</p>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-people me-1" viewBox="0 0 16 16">
                                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                </svg> <div className="medium d-inline-block">{dashboardData?.customerCount}</div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-lg-6">
                    <Link to="/base-station-list">
                        <div className="dashboard-box">
                            <p className="medium ft-23 mb-2">Total Base Stations</p>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-laptop me-1" viewBox="0 0 16 16">
                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                                </svg> <div className="medium d-inline-block">{dashboardData?.cpeCount}</div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
