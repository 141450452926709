import axios from "axios";
import { Dispatch } from "react";
import { dashboardInfo } from "../API";
import { Types } from "../types";

export const ClearAlert = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.ALERT_CLEAR });
    }
};

export const Navigation = (menu: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.NAVIGATION_LABEL, payload: menu });
    }
};

export const DashboardData = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.DASHBOARD_REQUEST });
        axios.get(dashboardInfo).then(resp => {
            console.log(resp)
            if (resp.status === 200 && resp.data?.isValid) {
                dispatch({ type: Types.DASHBOARD_SUCCESS, payload: resp.data });
            } else {
                dispatch({ type: Types.DASHBOARD_ERROR });
            }
        }).catch(err => {
            dispatch({ type: Types.DASHBOARD_ERROR });
        })
    }
};