import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AdminModal, DeleteModal } from '../../components/form/modal';
import { handleValidation } from '../../components/utilities/validations';
import { AddAdmin } from '../../store/customers/customersService';
import { RootState } from '../../store/rootReducer';
import { useAppDispatch } from '../../store/Store';


interface adminListInteface {
    firstName: string,
    lastName: string,
    userEmail: string,
    userPassword: string,
    userId: number,
}

export default function CustomerAdmin() {

    const dispatch = useAppDispatch();

    const { adminLoad, customers } = useSelector((state: RootState) => state.customers);

    const [state, setState] = useState({ companyName: '' });
    const [admins, setAdmins] = useState<adminListInteface[]>([]);
    const [value, setValue] = useState({ deleteModal: false, adminModal: false, isUpdate: false, isEdit: false, firstName: '', lastName: '', email: '', password: '', deleteName: '', deleteIndex: -1, editIndex: -1 });
    const [error, setError] = useState<any>({});

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            let find = customers.find(o => o.id === Number(id));
            setState(state => ({ ...state, companyName: find?.companyName }));
            console.log(find)
            setAdmins(find?.adminUserList);
        }
    }, [id, customers])

    const handleAdminChange = (e: any) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    const addAdmin = (e: any) => {
        e?.preventDefault();
        let conditions = [
            { condition: 'required', name: 'firstName', value: value.firstName, text: 'First Name' },
            { condition: 'required', name: 'lastName', value: value.lastName, text: 'Last Name' },
            { condition: 'required', name: 'emailAddr', value: value.email, text: 'Email' },
            { condition: 'email', name: 'emailAddr', value: value.email, text: 'Email' },
            { condition: 'required', name: 'password', value: value.password, text: 'Password' },
        ]
        let errors = handleValidation(conditions);
        if (!errors.isError) {
            let data = {
                userEmail: value.email,
                userPassword: value.password,
                firstName: value.firstName,
                lastName: value.lastName,
                userType: "CUSTOMER_ADMIN",
                customerId: id,
            }
            dispatch(AddAdmin(data)).then((resp: any) => {
                console.log('opopo', resp)
                if (resp.status === 200) {
                    setValue(value => ({ ...value, deleteModal: false, adminModal: false, isUpdate: false, isEdit: false, firstName: '', lastName: '', email: '', password: '', deleteName: '', deleteIndex: -1, editIndex: -1 }));
                }
            });
            // setValue({ ...value, adminModal: false, deleteModal: false, isEdit: false, firstName: '', email: '' })
            setError({});
        } else {
            setError(errors.errors);
        }
    }

    const adminEdit = (i: number) => {
        let find = admins.find(o => o.userId === i);
        if (find) {
            setValue({ ...value, adminModal: true, firstName: find.firstName, email: find.userEmail, lastName: find.lastName, password: find.userPassword, isEdit: true, editIndex: i })
        }
    }

    const handleAdminDelete = (index: number) => {
        let adminList = admins;
        adminList.splice(index, 1);
        setAdmins(adminList);
        setValue({ ...value, deleteName: '', deleteIndex: -1, deleteModal: false })
    }

    const editAdmin = (e: any) => {
        e.preventDefault();
        e?.preventDefault();
        let conditions = [
            { condition: 'required', name: 'firstName', value: value.firstName, text: 'First Name' },
            { condition: 'required', name: 'lastName', value: value.lastName, text: 'Last Name' },
            { condition: 'required', name: 'emailAddr', value: value.email, text: 'Email' },
            { condition: 'email', name: 'emailAddr', value: value.email, text: 'Email' },
            { condition: 'required', name: 'password', value: value.password, text: 'Password' },
        ]
        let errors = handleValidation(conditions);
        if (!errors.isError) {
            let data = {
                userEmail: value.email,
                // userPassword: value.password,
                firstName: value.firstName,
                lastName: value.lastName,
                userType: "CUSTOMER_ADMIN",
                customerId: id,
                userId: value.editIndex
            }
            dispatch(AddAdmin(data)).then((resp: any) => {
                console.log('opopo', resp)
                if (resp.status === 200) {
                    setValue(value => ({ ...value, deleteModal: false, adminModal: false, isUpdate: false, isEdit: false, firstName: '', lastName: '', email: '', password: '', deleteName: '', deleteIndex: -1, editIndex: -1 }));
                }
            });
            // setValue({ ...value, adminModal: false, deleteModal: false, isEdit: false, firstName: '', email: '' })
            setError({});
        } else {
            setError(errors.errors);
        }
    }

    const handleClose = () => {
        setValue({ ...value, adminModal: false, firstName: '', lastName: '', email: '', password: '' })
    }

    return (
        <React.Fragment>
            <div>
                <div className="row d-flex align-items-center mb-3 mb-md-5">
                    <div className="col-md-6">
                        <p className="medium ft-23 mb-2 mb-md-0">{`${state.companyName} - Admins`}</p>
                    </div>
                    <div className="col-md-6 text-start text-md-end">
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-lg-10">
                        <div className="row d-flex align-items-center mb-3 mb-md-4 mt-4">
                            <div className="col-md-6">
                                <p className="medium ft-23 mb-2 mb-md-0">Admin List</p>
                            </div>
                            <div className="col-md-6 text-start text-md-end">
                                <button onClick={() => setValue({ ...value, adminModal: true, isEdit: false, editIndex: -1, firstName: '', email: '' })} className="btn btn-theme" data-bs-toggle="modal" data-bs-target="#addadminModal"> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg> Add Admin</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {admins?.length > 0 ? admins.map((row: adminListInteface, i) => {
                                            return <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{row.firstName} {row.lastName}</td>
                                                <td>{row.userEmail}</td>
                                                <td>
                                                    <i className='fa fa-edit text-theme' onClick={() => adminEdit(row.userId)} />
                                                    {/* <i className='fa fa-trash text-danger' onClick={() => adminDelete(i)} /> */}
                                                </td>
                                            </tr>
                                        }) :
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>No Records</td>
                                                <td></td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {value.adminModal ?
                <AdminModal firstName={value.firstName} lastName={value.lastName} email={value.email} password={value.password} errors={error} onchange={(e: any) => handleAdminChange(e)} onclose={() => handleClose()} onsubmit={addAdmin} onedit={editAdmin} isedit={value.isEdit} load={adminLoad} /> : null}

            {value.deleteModal ?
                <DeleteModal text={`Are you sure to Delete ${value.deleteName}?`} onclose={() => setValue({ ...value, deleteModal: false, deleteName: '', deleteIndex: -1 })} ondelete={() => handleAdminDelete(value.deleteIndex)} /> : null}

        </React.Fragment>
    )
}