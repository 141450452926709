import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { RootState } from './store/rootReducer';
import Dashboard from './pages/dashboard';
import Sidebar from './pages/layout/sidebar';
import Forgot from './pages/login/forgot';
import Login from './pages/login/login';
import CustomerList from './pages/customers/customerList';
import CustomerAdd from './pages/customers/customerAdd';
import Footer from './pages/layout/footer';
import CustomerAdmin from './pages/customers/customerAdmin';
import DeviceList from './pages/devices/deviceList';
import DeviceAdd from './pages/devices/deviceAdd';

const Routers = () => {
    const { isLoggedIn } = useSelector((state: RootState) => state.login);
    const isToken = localStorage.getItem('sa.token');
    const [isToggle, setIsToggle] = useState(true);
    console.log(isLoggedIn)
    if (isLoggedIn && isToken) {
        return (
            <BrowserRouter>
                <div className="wrapper">
                    <Sidebar isToggle={isToggle} toggle={() => setIsToggle(!isToggle)} />
                    <div className={isToggle ? "main" : "main open1"}>
                        <div className="main-content">
                            <Routes>
                                <Route path='/base-station-edit/:id' element={<DeviceAdd />} />
                                <Route path='/base-station-add' element={<DeviceAdd />} />
                                <Route path='/base-station-list' element={<DeviceList />} />
                                <Route path='/customer-list' element={<CustomerList />} />
                                <Route path='/customer-admin/:id' element={<CustomerAdmin />} />
                                <Route path='/customer-edit/:id' element={<CustomerAdd />} />
                                <Route path='/customer-add' element={<CustomerAdd />} />
                                <Route path='/' element={<Dashboard />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Routes>
                        </div>
                        <Footer />
                    </div>
                </div>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot" element={<Forgot />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            </BrowserRouter>
        );
    }
};

export default Routers;
