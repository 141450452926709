import { validateEmail, validateURL } from "./functions";

export const handleValidation = (conditions: any) => {
    let errors: any = {};
    let isError: boolean = false;
    for (let i = 0; i < conditions.length; i++) {
        let obj = conditions[i];
        switch (obj.condition) {
            case 'required':
                if (!obj.value) {
                    errors[conditions[i].name] = conditions[i].text + ' Required';
                    isError = true;
                }
                break;
            case 'email':
                if (obj.value && !validateEmail(obj.value)) {
                    errors[conditions[i].name] = 'Invalid Email';
                    isError = true;
                }
                break;
            case 'url':
                if (obj.value && !validateURL(obj.value)) {
                    errors[conditions[i].name] = 'Invalid Url';
                    isError = true;
                }
                break;
            case 'number':
                if (obj.value && isNaN(obj.value)) {
                    errors[conditions[i].name] = 'Invalid Format';
                    isError = true;
                }
                break;
            case 'length':
                console.log(obj.value.length, obj)
                if (obj.value && (obj.min > obj.value.length || obj.max < obj.value.length)) {
                    errors[conditions[i].name] = 'Should be ' + obj.min + ' to ' + obj.max + ' Characters';
                    isError = true;
                }
                break;

            default:
                break;
        }
    }
    return { isError, errors };
}
