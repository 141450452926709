import { combineReducers } from "redux";
import { alertReducer, dashboardReducer, navigationReducer } from "./common/commonReducer";
import { customersReducer } from "./customers/customersReducer";
import { deviceReducer } from "./devices/deviceReducer";
import { loginReducer } from "./login/loginReducer";
import { Types } from "./types";

const combineReducer = combineReducers({
    alert: alertReducer,
    login: loginReducer,
    navigation: navigationReducer,
    customers: customersReducer,
    devices: deviceReducer,
    dashboard: dashboardReducer,
});

const rootReducer = (state: any, action: any) => {
    //action to clear application redux
    if (action.type === Types.USER_LOGOUT) {
        state = {};
    }
    return combineReducer(state, action);
}

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
