import React from 'react'
import { Required } from '../utilities/required';

type Props = {
    label: string,
    name: string,
    value: string,
    onchange: any,
    error?: any,
    required?: boolean,
    autoFocus?: boolean,
    options: any
}

export default function Select({ label, name, value, onchange, options = [], required = false, error = '', autoFocus = false }: Props) {
    return (
        <div className="form-group">
            <label className="mb-2">{label} {required ? <Required /> : null} {error ? <span className='text-danger floatRight'>{error}</span> : null}</label>
            <select className="form-select" name={name} value={value} onChange={onchange} autoFocus={autoFocus}>
                {options.length > 0 ?
                    <>
                        <option value={''}>Select Customer</option>
                        {options.map((val: any, i: number) => {
                            return <option key={val.key} value={val.key}>{val.name}</option>
                        })}
                    </>
                    : <option value=''>No Options</option>}
            </select>
        </div>
    )
}