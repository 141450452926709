import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteModal, ViewModal } from '../../components/form/modal';
import { Loader } from '../../components/utilities/loader';
import DataTable from '../../components/utilities/table';
import { Navigation } from '../../store/common/commonService';
import { CustomersList } from '../../store/customers/customersService';
import { RootState } from '../../store/rootReducer';

interface stateInterface {
    data: any[]
}
interface modalInterface {
    deleteModal: boolean,
    viewModal: boolean,
    deleteData: any,
    viewData: any
}

export default function CustomerList() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [state, setState] = useState<stateInterface>({ data: [] });
    const [modal, setModal] = useState<modalInterface>({ deleteModal: false, viewModal: false, deleteData: {}, viewData: {} });

    const { load, customers } = useSelector((state: RootState) => state.customers);

    useEffect(() => {
        dispatch(CustomersList());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (customers && !load) {
            console.log('this called')
            const data = customers.map((val: any, i: number) => {
                return {
                    no: i + 1,
                    companyName: val.companyName,
                    contactNumber: val.contactNumber,
                    contactEmail: val.contactEmail,
                    companyUrl: val.companyUrl,
                    customerAddress: val.customerAddress,
                    contactPerson: val.contactPerson,
                    id: val.id,
                    adminUserList: val.adminUserList,
                }
            });
            setState(state => ({ ...state, data: data }))
        }
        // eslint-disable-next-line
    }, [load]);

    const header: any[] = [
        { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 70 },
        { headerName: 'Company Name', field: 'companyName', editable: false, resizable: true, },
        { headerName: 'Contact Number', field: 'contactNumber', editable: false, resizable: true },
        { headerName: 'Contact Person', field: 'contactPerson', maxWidth: 150, editable: false, resizable: true },
        { headerName: 'Email', field: 'contactEmail', editable: false, resizable: true },
        {
            headerName: 'Website', editable: false, resizable: true, cellRendererFramework: (param: any) => {
                let url = param.data.companyUrl;
                let http = url?.search('http');
                let https = url?.search('https');
                if (http === -1 && https === -1 && url) {
                    url = 'https://' + url;
                }
                return (
                    <React.Fragment>
                        {param.data.companyUrl ?
                            <a href={url} className={'website'} target={'_blank'} rel={'noreferrer'}><u className="text-primary">Website</u></a> : null}
                    </React.Fragment>
                )
            }
        },
        {
            headerName: 'Action', editable: false, minWidth: 200, cellRendererFramework: (param: any) => {
                return (
                    <React.Fragment>
                        <i className='fa fa-eye icon-padding fz-17' onClick={() => viewHandle(param.data)} />
                        <i className='fa fa-edit text-primary icon-padding fz-17' onClick={() => navigate('/customer-edit/' + param.data.id)} />
                        {/* <i className='fa fa-trash text-danger icon-padding fz-17' onClick={() => deleteHandle(param.data)} /> */}
                        <span onClick={() => navigate('/customer-admin/' + param.data.id)} style={{ backgroundColor: '#4476b4', padding: '4px', color: 'white', borderRadius: '5px', cursor: 'pointer' }}><i className='fa fa-cogs' /> Add Admin</span>
                    </React.Fragment>
                )
            }
        },
    ];

    const viewHandle = (data: any) => {
        setModal(modal => ({ ...modal, viewModal: true, viewData: data }))
    }

    // const deleteHandle = (data: any) => {
    //     setModal(modal => ({ ...modal, deleteModal: true, deleteData: data }));
    // }

    return (
        <div>
            <div className="row d-flex align-items-center mb-3 mb-md-3">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">Customer List {load ? <Loader /> : null}</p>
                </div>
                <div className="col-md-6 text-start text-md-end">
                    <Link to='/customer-add'><button onClick={() => dispatch(Navigation('customerAdd'))} className="btn btn-theme"> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg> Add Customer</button></Link>
                </div>
            </div>

            <DataTable key={Math.random()} headers={header} data={state.data} pagination={true} globalSearch={true} />

            {modal.viewModal ?
                <ViewModal title={'Customer View'} onclose={() => setModal(modal => ({ ...modal, viewModal: false, viewData: {} }))}>
                    <div className="row d-flex align-items-center mb-3 mb-md-5">
                        <div className="col-md-6">
                            <p className="medium ft-23 mb-2 mb-md-0" style={{ marginLeft: '2rem' }}>Customer View</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row">
                                <div className="col-12 col-lg-6 mb-3">
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="currentColor" className="bi bi-briefcase me-2" viewBox="0 0 16 16">
                                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                        </svg>  Company Name
                                        <div className="ms-3 text-primary" style={{ overflow: 'auto' }}>
                                            {modal.viewData?.companyName}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="currentColor" className="bi bi-telephone me-2" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>  Contact number
                                        <div className="ms-3 text-primary" style={{ overflow: 'auto' }}>
                                            {modal.viewData?.contactNumber}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-6 mb-3">
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="currentColor" className="bi bi-person me-2" viewBox="0 0 16 16">
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                        </svg>  Contact Person name
                                        <div className="ms-3 text-primary" style={{ overflow: 'auto' }}>
                                            {modal.viewData?.contactPerson}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="currentColor" className="bi bi-envelope me-2" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                        </svg>  Email address
                                        <div className="ms-3 text-primary" style={{ overflow: 'auto' }}>
                                            {modal.viewData?.contactEmail}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="currentColor" className="bi bi-link-45deg me-2" viewBox="0 0 16 16">
                                            <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                            <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                                        </svg>  Website URL
                                        <div className="ms-3 text-primary" style={{ overflow: 'auto' }}>
                                            <a href={modal.viewData?.companyUrl} target="_blank" rel={'noreferrer'}><u className="text-primary">{modal.viewData?.companyUrl}</u></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100" />
                                <div className="col-12 col-lg-12 mb-3">
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="currentColor" className="bi bi-geo-alt me-2" viewBox="0 0 16 16">
                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                        </svg>  Address
                                        <div className="ms-3 text-primary" style={{ overflow: 'auto' }}>
                                            {modal.viewData?.customerAddress}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row d-flex align-items-center mb-3 mb-md-4 mt-4">
                                <div className="col-md-6">
                                    <p className="medium ft-23 mb-2 mb-md-0">Admin List</p>
                                </div>
                            </div>
                            {/* 
                                <div className="col-md-6 text-start text-md-end">
                                    <button className="btn btn-theme" data-bs-toggle="modal" data-bs-target="#addadminModal"> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg> Add Admin</button>
                            </div> */}
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modal.viewData?.adminUserList && modal.viewData?.adminUserList.length > 0 ? modal.viewData?.adminUserList.map((v: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{v.firstName} {v.lastName}</td>
                                                        <td>{v.userEmail}</td>
                                                    </tr>
                                                )
                                            }) : <tr>
                                                <td></td>
                                                <td>No Records</td>
                                                <td></td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </ViewModal>
                : null}

            {modal.deleteModal ?
                <DeleteModal onclose={() => setModal(modal => ({ ...modal, deleteModal: false, deleteData: {} }))} ondelete={() => ''} text={'Are you sure to Delete ' + modal.deleteData?.companyName + ' ?'} />
                : null}
        </div>
    )
}
