import { Types } from "../types";


interface IUserLoginState {
    loading: boolean;
    isLoggedIn: boolean;
    userData: any;
}

const initialState: IUserLoginState = {
    loading: false,
    isLoggedIn: false,
    userData: []
}

export const loginReducer = (state: IUserLoginState = initialState, action: any) => {
    switch (action.type) {

        case Types.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case Types.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                userData: action.payload,
            }
        case Types.LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
            }
        case Types.USER_LOGOUT:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                userData: []
            }
        // case REHYDRATE:
        //     return {
        //         ...state
        //     }

        default:
            return state;
    }
}