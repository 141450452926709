import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Input from '../../components/form/input'
// import { AdminModal, DeleteModal } from '../../components/form/modal';
import Textarea from '../../components/form/textarea';
import { Loader } from '../../components/utilities/loader';
import { handleValidation } from '../../components/utilities/validations';
import { AddCustomer } from '../../store/customers/customersService';
import { RootState } from '../../store/rootReducer';
import { useAppDispatch } from '../../store/Store';

// interface adminListInteface {
//     name: string,
//     email: string,
// }

export default function CustomerAdd() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { addLoad, customers } = useSelector((state: RootState) => state.customers);

    const [state, setState] = useState({ id: '', companyName: '', contactNumber: '', fullName: '', email: '', url: '', address: '' });
    // const [admins, setAdmins] = useState<adminListInteface[]>([]);
    const [value, setValue] = useState({ deleteModal: false, adminModal: false, isUpdate: false, isEdit: false, fullName: '', email: '', deleteName: '', deleteIndex: -1, editIndex: -1 });
    const [error, setError] = useState<any>({});

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            let find = customers.find(o => o.id === Number(id));
            setState(state => ({ ...state, companyName: find?.companyName, contactNumber: find?.contactNumber, fullName: find?.contactPerson, email: find?.contactEmail, url: find?.companyUrl, address: find?.customerAddress, id: id }));
            setValue(value => ({ ...value, isUpdate: true }));
        } else {
            setState(state => ({ ...state, companyName: '', contactNumber: '', fullName: '', email: '', url: '', address: '', id: '' }));
            setValue(value => ({ ...value, isUpdate: false }));
        }
        // eslint-disable-next-line
    }, [id])

    const handleChange = (e: any) => {
        setState(state => ({ ...state, [e.target.name]: e.target.value }))
    }

    // const handleAdminChange = (e: any) => {
    //     setValue({ ...value, [e.target.name]: e.target.value })
    // }

    // const addAdmin = (e: any) => {
    //     e?.preventDefault();
    //     let conditions = [
    //         { condition: 'required', name: 'fullName', value: value.fullName, text: 'Full Name' },
    //         { condition: 'required', name: 'emailAddr', value: value.email, text: 'Email' },
    //         { condition: 'email', name: 'emailAddr', value: value.email, text: 'Email' },
    //     ]
    //     let errors = handleValidation(conditions);
    //     if (!errors.isError) {
    //         let adminList = admins;
    //         adminList.push({ name: value.fullName, email: value.email });
    //         setAdmins(adminList);
    //         setValue({ ...value, adminModal: false, deleteModal: false, isEdit: false, fullName: '', email: '' })
    //         setError({});
    //     } else {
    //         setError(errors.errors);
    //     }
    // }

    // const adminDelete = (i: number) => {
    //     setValue({ ...value, deleteModal: true, deleteName: admins[i].name, deleteIndex: i });
    // }

    // const adminEdit = (i: number) => {
    //     setValue({ ...value, adminModal: true, fullName: admins[i].name, email: admins[i].email, isEdit: true, editIndex: i })
    // }

    // const handleAdminDelete = (index: number) => {
    //     let adminList = admins;
    //     adminList.splice(index, 1);
    //     setAdmins(adminList);
    //     setValue({ ...value, deleteName: '', deleteIndex: -1, deleteModal: false })
    // }

    // const editAdmin = () => {
    //     let adminList = admins;
    //     adminList[value.editIndex].name = value.fullName;
    //     adminList[value.editIndex].email = value.email;
    //     setAdmins(adminList);
    //     setValue({ ...value, adminModal: false, deleteModal: false, isEdit: false, fullName: '', email: '' })
    // }

    const handleSubmit = (e: any) => {
        let conditions = [
            { condition: 'length', name: 'contactNumber', value: state.contactNumber, min: 8, max: 15, text: 'Contact Number' },
            { condition: 'number', name: 'contactNumber', value: state.contactNumber, text: 'Contact Number' },
            { condition: 'email', name: 'email', value: state.email, text: 'Email' },
            { condition: 'url', name: 'url', value: state.url, text: 'Url' },
            { condition: 'required', name: 'companyName', value: state.companyName, text: 'Company Name' },
            { condition: 'required', name: 'email', value: state.email, text: 'Email' },
            { condition: 'required', name: 'contactNumber', value: state.contactNumber, text: 'Contact Number' },
            { condition: 'required', name: 'fullName', value: state.fullName, text: 'Contact Person' },
        ]
        let errors = handleValidation(conditions);
        if (!errors.isError) {
            setError({});
            let data = {
                companyName: state.companyName,
                companyUrl: state.url,
                customerAddress: state.address,
                contactNumber: state.contactNumber,
                contactEmail: state.email,
                contactPerson: state.fullName,
                id: !state.id ? null : state.id,

            }
            dispatch(AddCustomer(data)).then((res: any) => {
                if (res.status === 200 && res.data?.isValid) {
                    navigate('/customer-list');
                }
            });
        } else {
            setError(errors.errors)
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="row d-flex align-items-center mb-3 mb-md-5">
                    <div className="col-md-6">
                        <p className="medium ft-23 mb-2 mb-md-0">{`${value.isUpdate ? 'Edit' : 'Add'} Customer`}</p>
                    </div>
                    <div className="col-md-6 text-start text-md-end">
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-lg-10">
                        <div className="row">
                            <div className="col-12">
                                <Input type='text' label='Company Name' autoFocus={true} value={state.companyName} placeholder='Company Name' name='companyName' onchange={handleChange} error={error?.companyName} required={true} />
                            </div>
                            <div className="col-12">
                                <Textarea label='Address' value={state.address} placeholder='Address' name='address' onchange={handleChange} maxlength={511} />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input type='text' label='Contact Number' value={state.contactNumber} placeholder='Contact Number' name='contactNumber' onchange={handleChange} error={error?.contactNumber} required />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input type='text' label='Contact Person Full Name' value={state.fullName} placeholder='Contact Person Full Name' name='fullName' onchange={handleChange} error={error?.fullName} required />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input type='text' label='Email Address' value={state.email} placeholder='Email Address' name='email' onchange={handleChange} error={error?.email} required />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input type='text' label='Website URL' value={state.url} placeholder='Website URL' name='url' onchange={handleChange} error={error?.url} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center mt-4 mb-5">
                                <button className={value.isUpdate ? "btn btn-warning" : "btn btn-theme"} onClick={(e) => addLoad ? null : handleSubmit(e)}> {addLoad ? <Loader /> : value.isUpdate ? <i className='fa fa-edit' /> : <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>} {` ${addLoad ? 'Loading' : `${value.isUpdate ? 'Edit ' : 'Add '} Customer`}`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {value.deleteModal ?
                <DeleteModal text={`Are you sure to Delete ${value.deleteName}?`} onclose={() => setValue({ ...value, deleteModal: false, deleteName: '', deleteIndex: -1 })} ondelete={() => handleAdminDelete(value.deleteIndex)} /> : null} */}

        </React.Fragment>
    )
}