import { createStore, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import jwtDecode from 'jwt-decode';
import { UserLogout } from './login/loginService';
import { useDispatch } from 'react-redux';
import { Action, ThunkDispatch } from "@reduxjs/toolkit";

let middleware: any[] = [];

const persistConfig = {
    key: 'superadmin',
    storage,
}

interface MyToken {
    name: string;
    exp: number;
    // whatever else is in the JWT.
}

const checkTokenExpirationMiddleware = (store: any) => (next: any) => (action: any) => {
    const token = localStorage.getItem("sa.token") ?? '';
    if (token && jwtDecode<MyToken>(token).exp < (Date.now() / 1000)) {
        store.dispatch(UserLogout());
        next(action);
    }
    next(action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

// if (process.env.NODE_ENV === 'development') {
middleware = [...middleware, logger, thunk, checkTokenExpirationMiddleware];
// } else {
//     middleware = [...middleware, thunk];
// }
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();

export default store;
