import axios from "axios";
import { Dispatch } from "react";
import { addUserUrl, customers, listCustomers } from '../API';
import { Types } from "../types";

export const CustomersList = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.CUSTOMERS_REQUEST });
        axios.get(listCustomers).then(resp => {
            console.log(resp)
            if (resp.status === 200 && resp.data?.isValid) {
                dispatch({ type: Types.CUSTOMERS_SUCCESS, data: resp.data.customerList });
            } else {
                dispatch({ type: Types.CUSTOMERS_ERROR });
            }
        }).catch(err => {
            dispatch({ type: Types.CUSTOMERS_ERROR });
        })
    }
};

export function AddCustomer(data: any) {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.CUSTOMERS_ADD_LOADING });
        return axios.post(customers, data).then(resp => {
            if (resp.status === 200 && resp.data?.isValid) {
                dispatch({ type: Types.CUSTOMERS_ADD_SUCCESS });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Customer ${data.id ? 'Updated' : 'Added'} Successfully`, duration: 2000 } });
            } else {
                dispatch({ type: Types.CUSTOMERS_ADD_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed to Create Customer, Try Again' } });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.CUSTOMERS_ADD_ERROR });
            dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed to Create Customer, Try Again' } });
        });
    }
};

export const AddAdmin = (data: any) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({ type: Types.ADMIN_ADD_LOADING });
        return axios.post(addUserUrl + '/user', data).then(resp => {
            if (resp.status === 200 && resp.data?.isValid) {
                dispatch({ type: Types.ADMIN_ADD_SUCCESS });
                dispatch(CustomersList());
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Admin ${data.userId ? 'Updated' : 'Added'} Successfully`, duration: 2000 } });
            } else {
                dispatch({ type: Types.ADMIN_ADD_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data?.message ?? 'Failed to Create Admin, Try Again' } });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.ADMIN_ADD_ERROR });
            dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed to Create Admin, Try Again' } });
        })
    }
};
