import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navigation } from '../../store/common/commonService';
import { UserLogout } from '../../store/login/loginService';
import { RootState } from '../../store/rootReducer';

function Sidebar(props: any) {
    const { userData } = useSelector((state: RootState) => state.login);
    const dispatch = useDispatch();
    const [state, setState] = useState({ notification: false, profile: false });
    const { navigation } = useSelector((state: RootState) => state);

    const notification = useRef<any>(null);
    const profile = useRef<any>(null);

    const logoutHandle = (e: any) => {
        dispatch(UserLogout())
    }

    const toggleHandle = () => {
        props.toggle();
    }

    const navigationHandle = (menu: string) => {
        dispatch(Navigation(menu));
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (notification.current && !notification.current.contains(event.target)) {
                setState(state => ({ ...state, notification: false }));
            }
            if (profile.current && !profile.current.contains(event.target)) {
                setState(state => ({ ...state, profile: false }));
            }
        }

        const handleEscape = (event: any) => {
            if (event.keyCode === 27) {
                setState(state => ({ ...state, profile: false, notification: false }));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleEscape);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleEscape);
        };
    }, [notification, profile]);

    const getInitial = () => {
        let initial = 'A';
        if (userData?.firstName) {
            initial = userData.firstName[0] ? userData.firstName[0].toUpperCase() : 'A';
        }
        return initial;
    }

    return (
        <React.Fragment>
            <div className="navbar">
                <div className={props.isToggle ? "topnav" : "topnav open1"}>
                    <div className="menu-toggle" onClick={toggleHandle}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </div>
                    <ul className="list-unstyled mb-0">
                        <li className="dropdown progile-dropdown list-inline-item" onClick={() => setState({ ...state, profile: true })}>
                            <span className={`dropdown-toggle text-muted pr-0 ${state.profile ? 'show' : null}`} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <span className="avatar avatar-sm mt-2">
                                    {/* <img src="/img/face-1.jpg" alt="..." className="avatar-img rounded-circle" /> */}
                                    <span className="avatar-img rounded-circle profileIcon">{getInitial()}</span>
                                </span>
                            </span>
                            <div ref={profile} className={`dropdown-menu dropdown-menu-end ${state.profile ? 'show' : null}`} aria-labelledby="navbarDropdownMenuLink" data-bs-popper="none" >
                                {/* <span className="dropdown-item">Profile</span>
                                <span className="dropdown-item">Settings</span> */}
                                <span className="dropdown-item logout" onClick={logoutHandle}>Log Out</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={props.isToggle ? "sidebar-left" : "sidebar-left close1"}>
                <div className="sidebar-left-pad">
                    <div className="colse-sidebar" onClick={toggleHandle}> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg></div>
                    <img src="/img/logo.png" className="img-fluid s-logo" alt="img" />
                    <div className="sidebar-ul">
                        <ul className="list-unstyled">
                            <li onClick={() => navigationHandle('dashboard')}><Link to="/" className={navigation.menu === 'dashboard' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-house me-2" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                            </svg> Dashboard</Link></li>
                        </ul>
                        <p className="op-7 mt-4">Customers</p>
                        <ul className="list-unstyled">
                            <li onClick={() => navigationHandle('customerList')}><Link to="customer-list" className={navigation.menu === 'customerList' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-people me-2" viewBox="0 0 16 16">
                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                            </svg> Customer List</Link></li>
                            <li onClick={() => navigationHandle('customerAdd')}><Link to="customer-add" className={navigation.menu === 'customerAdd' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-person-plus me-2" viewBox="0 0 16 16">
                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                            </svg> Add Customer</Link></li>
                        </ul>
                        <p className="op-7 mt-4">Base Station</p>
                        <ul className="list-unstyled">
                            <li onClick={() => navigationHandle('deviceList')}><Link to="base-station-list" className={navigation.menu === 'deviceList' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-laptop me-2" viewBox="0 0 16 16">
                                <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                            </svg> Base Station List</Link></li>
                            <li onClick={() => navigationHandle('deviceAdd')}><Link to="base-station-add" className={navigation.menu === 'deviceAdd' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus me-1" viewBox="0 0 16 16">
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg> Add Base Station</Link></li>
                        </ul>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-theme" onClick={logoutHandle} > <img src="/img/icon/logout.png" className="img-fluid me-2" alt="img" />Logout</button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default Sidebar;
