import { Types } from "../types";

interface ICustomersState {
    load: boolean,
    addLoad: boolean,
    adminLoad: boolean,
    customers: any[],
}

const initialState: ICustomersState = {
    load: false,
    addLoad: false,
    adminLoad: false,
    customers: [],
}

interface INavigation {
    menu: string,
}

export const customersReducer = (state: ICustomersState = initialState, action: any) => {
    switch (action.type) {

        case Types.CUSTOMERS_REQUEST:
            return {
                ...state,
                load: true,
            }

        case Types.CUSTOMERS_SUCCESS:
            return {
                ...state,
                load: false,
                customers: [...action.data]
            }

        case Types.CUSTOMERS_ERROR:
            return {
                ...state,
                load: false,
            }

        case Types.CUSTOMERS_ADD_LOADING:
            return {
                ...state,
                addLoad: true,
            }

        case Types.CUSTOMERS_ADD_SUCCESS:
            return {
                ...state,
                addLoad: false,
            }

        case Types.CUSTOMERS_ADD_ERROR:
            return {
                ...state,
                addLoad: false,
            }

        case Types.ADMIN_ADD_LOADING:
            return {
                ...state,
                adminLoad: true,
            }

        case Types.ADMIN_ADD_SUCCESS:
            return {
                ...state,
                adminLoad: false,
            }

        case Types.ADMIN_ADD_ERROR:
            return {
                ...state,
                adminLoad: false,
            }

        default:
            return state;
    }
}

export const navigationReducer = (state: INavigation = { menu: 'dashboard' }, action: any) => {
    switch (action.type) {

        case Types.NAVIGATION_LABEL:
            return {
                menu: action.payload,
            }

        default:
            return state;
    }
}